body {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    margin: 0px;
}

.hidden {
    display: none;
}

.heading {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    background-color: #01579B;
    border-bottom: 4px solid #00467c;
    color: white;
    padding: 2px;
    text-transform: capitalize;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    height: 33px;
    width: 100%;
    box-sizing: border-box;

    h2 {
        flex-grow: 1;
    }

    .expand-collapse-icon {
        width: 35px;
        text-align: center;
        line-height: 20px;
        font-size: 30px;
        font-weight: bold;
    }
}

.collapsible {
    padding-top: 5px;
    padding-bottom: 5px;
}

h1 {
    font-size: inherit;
    padding: inherit;
    margin: inherit;
}

h2 {
    font-size: inherit;
    padding: inherit;
    margin: inherit;
}

.blur {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: white;
    opacity: 80%;
    backdrop-filter: blur(10px); // Firefox doesn't support this :(
}

.menu {
    .menu-bar {
        height: 40px;
        display: flex;
        flex-direction: row;

        .menu-icon {
            width: 32px;
            height: 32px;
        }

        .view-title {
            font-size: 26px;
            line-height: 24px;
            text-align: center;
            flex-grow: 1;
        }
    }

    .menu-contents {
        position: absolute;
        background-color: white;
        width: 100%;

        .menu-item {
            cursor: pointer;
            height: 50px;
            border: 1px solid lightgray;
            padding: 3px;
            vertical-align: middle;

            .character-name {
                font-weight: bold;
                font-size: 18px;
            }

            .character-description {
                font-size: 14px;
            }

            .option {
                line-height: 50px;
                white-space: nowrap;
            }
        }
    }
}

.hero-points-container {
    .hero-points-row {
        display: flex;
        flex-direction: row;
        text-align: center;
        font-size: 24px;
        line-height: 24px;
        height: 28px;
        
        &.hidden {
            display: none;
        }

        .hero-points-minus, .hero-points-plus {
            font-weight: bold;
            cursor: pointer;
            user-select: none;
            width: 30px;
            height: 24px;
            font-size: 30px;
        }
    }
}

.deck-container {
    .deck-header {
        display: flex;
        flex-direction: row;

        h2 {
            flex: auto;
        }

        div {
            flex: auto;
        }
    }

    .favored-cards {
        font-weight: bold;
    }

    .deck-row {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        padding: 2px;
        border-bottom: 1px solid lightgray;

        div {
            flex: auto;

            &.deck-card-type {
                max-width: 80px;
            }

            &.deck-card-type-limit {
                max-width: 20px;
            }

            &.deck-upgrades {
                .deck-upgrade {
                    display: inline-block;
                }
            }
        }
    }
}

.abilities-container {
    .abilities-row {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid lightgray;

        .ability-primary-row {
            display: flex;
            flex-direction: row;
            width: 120px;

            div {
                flex: auto;

                &.ability-primary-title {
                    font-weight: bold;
                }

                &.ability-primary-modifier {
                    text-align: right;
                }
            }
        }
    
        .ability-primary-die {
            width: 40px;
            text-align: right;
            padding-right: 5px;
        }

        .ability-primary-upgrades {
            .ability-upgrade {
                display: inline-block;
            }
        }

        .ability-secondary-row {
            display: flex;
            flex-direction: row;

            div {
                flex: auto;

                &.ability-secondary-modifier {
                    text-align: right;
                }
            }
        }
    }
}

.hand-size-container {
    .hand-size-row {
        display: flex;
        flex-direction: row;

        .hand-size-item {
            min-width: 30px;
        }
    }
}

.proficiencies-container {
    .proficiencies-row {
        display: flex;
        flex-direction: row;

        .proficiencies-item {
            min-width: 100px;
        }
    }
}

.upgrade-checkbox {
    display: inline-block;
    min-width: 22px;
    height: 22px;
    text-align: center;
    color: black;
    padding: 0 2px 2px 2px;
    

    label {
        border: 1px solid lightgray;
        border-radius: 5px;
        display: block;
        font-size: 12px;
        line-height: 22px;
    }

    input:checked ~ label {
        border: 1px solid black;
        background-color: rgb(32, 32, 32);
        color: white;
    }

    input {
        display: none;
    }
}
